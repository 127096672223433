import { ContentfulDfdsService } from 'app/shared/resources/contentful-dfds.service'
import { TravelTrackingService } from 'app/shared/tracking/travel-tracking.service'
import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { Location } from '@angular/common'
import { Observable, Subject, of, combineLatest, empty, forkJoin, Subscription, pipe, merge } from 'rxjs'
import { debounceTime, startWith, map, skip, filter } from 'rxjs/operators'
import { convert as JsJodaConvert, LocalDate } from 'js-joda'
import { ButtonStateType } from 'app/shared/button/button.component'
import { ResourceService } from 'app/shared/resources/resource.service'
import { QuoteChanged, PriceSpecificationType, UserQuoteDetails, OneWayUserQuoteDetails } from 'app/shared/quote/quote.events'
import { TravelService, TravelParams, FullPostParams, VehicleAndTrailerType } from 'app/travel/travel.service'
import { ITravelWire, CodeStatus, ITravelPassengerRange, ITravelDepartureDatepicker, TravelForm } from 'app/travel/travel.interfaces'
import { HybridService } from 'app/shared/hybrid/hybrid.service'
import { StepService } from 'app/shared/steps/step.service'
import { isBrowserStorageSupported, entries, FrontendError, distinctUntilChangedDeep } from 'app/shared/utils'
import { IFlowReservation, SelectionTypeEnum, LocalStorageStepData } from 'app/shared/steps/step.model'
import { Quote } from 'app/shared/quote/quote.models'
import { ChildrenAges, DisabledDateValidator } from 'app/travel/travel.models'
import { QuoteService } from 'app/shared/quote/quote.service'
import { LayoutState } from 'app/shared/layout/layout-state'
import { TrackingFlowStep } from 'app/shared/tracking/tracking-wire.interfaces'
import { TrackingService } from 'app/shared/tracking/tracking.service'
import { ContentfulSeabookWebService, FlowTravel } from 'app/shared/resources/contentful-seabookweb.service'
import { Entry } from 'contentful'
import { LoggingService } from 'app/shared/logging.service'
import { StorageService } from 'app/shared/storage.service'
import { selectedVehicle } from 'app/vehicles/vehicles-selector/vehicles-selector.component'
import { AgentProfileService, AgentProfileWire } from 'app/agent/profile/agent-profile.service'
import { CookieService } from 'app/shared/cookie.service'

@Component({
  selector: 'sbw-flow-travel',
  templateUrl: './flow-travel.component.html',
  styleUrls: ['./flow-travel.component.css'],
})
export class FlowTravelComponent implements OnInit {
  errorList = []
  backendErrors = []
  frontendErrors = {
    dates: new FrontendError(() => this.updateErrors()),
    trailer: new FrontendError(() => this.updateErrors()),
    pets: new FrontendError(() => this.updateErrors()),
  }

  updateErrors() {
    this.errorList = this.backendErrors.concat(
      entries(this.frontendErrors)
        .map((kv) => kv.value.errors())
        .reduce((a, b) => a.concat(b), [])
    )
  }

  travelForm: FormGroup

  public travelWire: ITravelWire
  public buttonState: ButtonStateType = 'activated'

  public quoteData: Observable<QuoteChanged> = empty()
  public codeStatus: CodeStatus
  public showPets = true
  public supportMultipleVehicles = false
  public travelContent?: any
  public isAgentRoute: boolean
  public validatedDepartureCode: Subject<string> = new Subject() // Used to update the quote or it would display routes in the quote that doesn't match
  private backendData = new Subject()
  public giveAttentionChildAges = false
  public locale: string // used by travel-departure component
  public datePickerInfoLabel: string
  public preferredAgentRoute: string

  private flowReservation: IFlowReservation
  private loadFromContentfulPromise: Promise<Entry<FlowTravel>>
  get departureSelections(): FormGroup {
    return this.travelForm.get('departureSelections') as FormGroup
  }
  get passengerSelections(): FormGroup {
    return this.travelForm.get('passengerSelections') as FormGroup
  }
  get outboundRouteCode(): FormControl {
    return this.departureSelections.get('outboundRouteCode') as FormControl
  }
  get outboundDepartureDate(): FormControl {
    return this.departureSelections.get('outboundDepartureDate') as FormControl
  }
  get returnDepartureDate(): FormControl {
    return this.departureSelections.get('returnDepartureDate') as FormControl
  }
  get oneWay(): FormControl {
    return this.departureSelections.get('oneWay') as FormControl
  }
  get passengerRanges(): ITravelPassengerRange {
    return this.travelForm.get('passengerRanges').value
  }
  get vehicleSelections(): FormGroup {
    return this.travelForm.get('vehicleSelections') as FormGroup
  }
  get code(): FormControl {
    return this.travelForm.get('code') as FormControl
  }

  constructor(
    private formBuilder: FormBuilder,
    private travelService: TravelService,
    private resourceService: ResourceService,
    private route: ActivatedRoute,
    private hybridService: HybridService,
    private stepService: StepService,
    private title: Title,
    private quoteService: QuoteService,
    private layoutState: LayoutState,
    private cookieService: CookieService,
    private trackingService: TrackingService,
    private contentfulSeabookWebService: ContentfulSeabookWebService,
    private contentfulDfdsService: ContentfulDfdsService,
    private location: Location,
    private travelTrackingService: TravelTrackingService,
    private loggingService: LoggingService,
    private storage: StorageService,
    private agentService: AgentProfileService
  ) {}

  ngOnInit() {
    this.locale = this.resourceService.getLocale()
    let routeSnapshot = this.route.snapshot
    this.isAgentRoute = routeSnapshot.data.isAgent
    const agentLoggedIn = this.cookieService.getAuthType() === 'agent'

    console.log("Agent logged in on travel:", agentLoggedIn)

    if (!agentLoggedIn && this.isAgentRoute) {
      this.ensureAgentLoggedIn()
    }
    else {
    this.flowReservation = this.stepService.combineToReservation()
    let params = this.getTravelParamsFromQuery(routeSnapshot)
    this.giveAttentionChildAges = !!(params.chd || params.inf)
    this.resourceService
      .loadResourcesPromise(
        params.routeOut || 'Default',
        this.locale,
        ['Travel', 'Account', 'Menu', 'Common', 'Currency', 'Quote'].concat(this.isAgentRoute ? ['Agent', 'MenuAgent'] : [])
      )
      .then(() => {
        this.resourceService.get('PAGE_TITLE', false).subscribe((t) => this.title.setTitle(t))
        this.loadFromContentfulPromise = this.contentfulSeabookWebService.loadFlowTravel()

        this.setOfferTextInDatePicker(params)

        if (!isBrowserStorageSupported()) {
          this.loadInitialData(params)
        } else {
          if (params.any()) {
            this.travelService.postHybridSimple(params).subscribe((s) => {
              if (s.nextStepUrl) {
                this.saveStepsAndGoToNext(s.nextStepUrl, s.step, s.quote)
              } else {
                let unmappedParams = this.getTravelParamsFromQuery(routeSnapshot)

                // hack for dieppe-newhaven solving problem with showing correct product when children are added in quickbook and age(s) for children needs to be provided
                this.fixProductForDieppeNewhaven(s, unmappedParams)

                this.loadInitialData(unmappedParams)
              }
            })
          } else {
            this.setStorageDataOnParamsOnRevisit(params)
            this.loadInitialData(params)
          }
          this.trackingService.trackFlow(TrackingFlowStep.TRAVEL, 'Ferry booking', this.flowReservation, true)
        }
      })
    }
  }

  setOfferTextInDatePicker(params: TravelParams) {
    if (params.miniBookId) {
      this.storage.setItem('sbw_MiniBookId', params.miniBookId)
      this.contentfulDfdsService.getMinibookById(params.miniBookId).then((miniBookEntry) => {
        this.datePickerInfoLabel = miniBookEntry && miniBookEntry.fields.offerCodeInfoText
      })
    } else this.storage.removeItem('sbw_MiniBookId')
  }

  getTravelParamsFromQuery(routeSnapshot): TravelParams {
    return TravelParams.fromQuery(routeSnapshot.queryParams)
  }

  //Hack for dieppe-newhaven and Jersey routes
  private fixProductForDieppeNewhaven(s, unmappedParams: TravelParams) {
    const validRoutes = ["dpnh", "nhdp", "smsh", "shsm", "shpm", "pmsh", "shpo", "posh"];
    if (
      s.product &&
      unmappedParams &&
      unmappedParams.routeOut &&
      validRoutes.includes(unmappedParams.routeOut.toLowerCase())
    ) {
      unmappedParams.product = s.product;
    }
  }
  initForm(params: TravelParams) {
    this.travelForm = this.formBuilder.group({
      departureSelections: this.formBuilder.group({
        outboundRouteCode: this.travelWire.departureOut.routeCode,
        outboundDepartureDate: this.formBuilder.control(this.travelWire.departureOut.date.selectedDate, [
          Validators.required,
          new DisabledDateValidator(() => this.travelWire.departureOut.date.disabledDates).validate,
        ]),
        returnDepartureDate: this.formBuilder.control(this.travelWire.departureReturn.date.selectedDate, [
          Validators.required,
          new DisabledDateValidator(() => this.travelWire.departureReturn.date.disabledDates).validate,
        ]),
        oneWay: this.formBuilder.control(params.routeHome == 'ONE_WAY'),
      }),
      passengerRanges: this.travelWire.passengerRanges,
      passengerSelections: this.PassengerSelectionControl(params),
      vehicleSelections: this.vehicleSelectionControl(params),
      code: this.formBuilder.control(params.offer || params.product, { updateOn: 'blur' }),
    })
    combineLatest(
      this.travelForm.get('departureSelections.outboundDepartureDate').statusChanges,
      this.travelForm.get('departureSelections.returnDepartureDate').statusChanges
    ).subscribe((a) => {
      if (!a.find((r) => r == 'INVALID')) this.frontendErrors.dates.disable()
    })
  }

  checkUserSelectedDates(travelServiceResponse: ITravelWire, params: TravelParams) {
    let check = (dates: ITravelDepartureDatepicker, user: LocalDate) => {
      if (!dates || !dates.selectedDate || !user) return
      if (!dates.selectedDate.isEqual(user)) {
        dates.selectedDate = null
        return true
      }
    }
    let outbound = check(travelServiceResponse.departureOut.date, params.depDateOutJoda)
    let ret = check(travelServiceResponse.departureReturn && travelServiceResponse.departureReturn.date, params.depDateHomeJoda)
    if (outbound || ret) {
      this.frontendErrors.dates.activate()
      this.travelTrackingService.trackNoCapacityOnSelectedDate()
      this.loggingService.logWarn({
        message: 'No capacity on the selected date for {Code}',
        params: [params.offer || params.product],
      })
    }
  }

  public validateVehicleParams(params: TravelParams) {
    const vehicle = selectedVehicle(this.travelWire.vehicles, this.vehicleSelections)
    if (vehicle && params.vty && params.tty && params.tty.toLowerCase() == 'yes') {
      this.frontendErrors.trailer.parameters['vehicleType'] = vehicle.fullDescription
      this.frontendErrors.trailer.activate()
    }
  }

  public validatePetsParams(params: TravelParams) {
    if (params.pet) {
      const sv = selectedVehicle(this.travelWire.vehicles, this.vehicleSelections)
      if (!(sv && sv.petsAllowed)) {
        this.frontendErrors.pets.activate()
      }
    }
  }

private PassengerSelectionControl(params: TravelParams) {
    return this.formBuilder.group({
        adultCount: [params.adl || 1],
        childCount: [Math.min((params.chd || 0) + (params.inf || 0), 12)],
        petCount: [params.pet || 0],
        childrenAges: this.formBuilder.array(this.getChildAgeControls(params.chdAges)),
    }, {
        validators: (c) => this.childAgeValidator(c as FormGroup)
    });
}

  private childAgeValidator(group: FormGroup) {
    const ca = group.get('childrenAges')
    const cc = group.get('childCount')
    if (!ca || !cc) return null
    return ChildrenAges.fromStrings(ca.value, cc.value).allAgesProvided ? null : { childAgeValidator: { valid: false } }
  }

  private getChildAgeControls(childAges): FormControl[] {
    let a = childAges || []
    return new Array(this.travelWire.passengerRanges.childPassengerRange.slice(-1)[0])
      .fill(null)
      .map((_, i) => this.formBuilder.control(a[i] != undefined ? a[i] : ''))
  }

  private setMultipleVehicles(vehicleCode: string, routeCode: string) {
    const isShortSea = routeCode.substr(0, 2).toLowerCase() === 'dv' || routeCode.slice(-2).toLowerCase().toLowerCase() === 'dv'
    const isDpnh = routeCode.substr(0, 2).toLowerCase() === 'dp' || routeCode.slice(-2).toLowerCase().toLowerCase() === 'dp'
    this.supportMultipleVehicles = !isShortSea && !isDpnh && !!vehicleCode && vehicleCode !== 'NCAR'
    if (!this.supportMultipleVehicles) this.travelForm.patchValue({ vehicleSelections: { count: 1 } })
  }

  private vehicleSelectionControl(params: TravelParams) {
    return this.formBuilder.group({
      // The vehicle selections component only takes one vehicle and trailer as parameter where the backend supports an array of vehicles.
      // That's why I made this cut here, so it's easy to change
      vehicle: new FormControl(params.vty || ''),
      trailer: new FormControl(params.tty || ''),
      count: new FormControl(params.vehicleCount || 1),
    })
  }

  private debounce() {
    return debounceTime(1)
  }

  saveStepsAndGoToNext(nextUrl: string, step: any, quote: any) {
    step.step.flowActivated = new LocalStorageStepData(SelectionTypeEnum.User, true)
    this.stepService.saveStepData(step.step, '/', true)
    this.trackingService.saveFlowTrackingData(step.trackingData)
    this.quoteService.saveQuoteInSessionStorage(quote)
    this.clearUrlParamsToSupportBrowserBack()
    this.hybridService.changeWindowLocation(nextUrl)
  }

  private clearUrlParamsToSupportBrowserBack() {
    this.location.go('/' + this.route.snapshot.routeConfig.path)
  }

  continue() {
    this.layoutState.setIsContinueLoading(true)
    this.buttonState = 'spinner'

    // Check if users should go to NBE
    this.travelService.getLoaderUrl(this.travelForm.value, this.isAgentRoute).subscribe((data) => {
      if (data.url?.includes('yourbooking')) {
        setTimeout(() => {
          this.layoutState.setIsContinueLoading(false)
          const url = `${window.location.origin}/${data.url}`
          window.location.href = url
        }, 1000)
      } else {
        // Users continue in old flow
        let arg = FullPostParams.fromForm(this.travelForm, this.findReturnRoute(), this.travelWire.vehicles)

        this.travelService.postHybridFull(arg).subscribe((s) => {
          if (s.nextStepUrl) {
            this.saveStepsAndGoToNext(s.nextStepUrl, s.step, s.quote)
            this.layoutState.setIsContentLoaded(false)
            this.layoutState.setIsContinueLoading(false)
          } else {
            this.backendErrors = s.errorList.map((e) => e.message)
            this.updateErrors()
            this.buttonState = 'activated'
            this.layoutState.setIsContinueLoading(false)
          }
        })
      }
    })
  }

  private findReturnRoute() {
    return this.oneWay.value
      ? 'ONE_WAY'
      : this.getTravelParamsFromQuery(this.route.snapshot).routeHome ||
          this.travelWire.departureReturn.routeCode ||
          this.flowReservation.returnRouteCode
  }

  private loadInitialData(params: TravelParams) {
    let travelFlowObservable = this.travelService.getTravelFlow(this.salesChannel(), {
      departureRoute: params.routeOut,
      departureDate: params.depDateOutJoda,
      code: params.offer || params.product,
      numberOfPassengers: (params.adl || 1) + (params.chd || 0),
      returnDate: params.depDateHomeJoda,
      childAges: params.chdAges,
      vehicleType: params.vty,
    })

    let agentDetailsObservable = this.agentService.getAgentDetails(this.isAgentRoute)

    // forkJoin is required to display the chosen vehicle name (from getTravelFlow) in the error message from contentful
    forkJoin(travelFlowObservable, this.loadFromContentfulPromise, agentDetailsObservable).subscribe(
      ([travelServiceResponse, flowTravelContent, agentDetailsWire]) => {
        this.checkUserSelectedDates(travelServiceResponse, params)
        this.travelWire = travelServiceResponse
        this.travelContent = flowTravelContent
        this.updateErrorResources(flowTravelContent)
        this.initForm(params)
        this.quoteDataChanged()
        this.trailerSelectionChanged()
        this.vehicleChanges()
        this.oneWayChanged()
        this.handleDataFromBackend(travelServiceResponse)
        this.validateVehicleParams(params)
        this.validatePetsParams(params)
        this.continueButtonEnabledChanged()
        this.backendParametersChanged()
        this.agentPreferredRoute(agentDetailsWire)
        this.layoutState.setIsContentLoaded(true)
      }
    )
  }

  private updateErrorResources(flowTravelContent: Entry<FlowTravel>) {
    this.frontendErrors.pets.resource = flowTravelContent.fields.petParameterRequiresVehicle
    this.frontendErrors.trailer.resource = flowTravelContent.fields.vehicleParameterRequiresTrailerType
    this.frontendErrors.dates.resource = flowTravelContent.fields.selectedDateNotAvailable
  }

  private quoteDataChanged() {
    combineLatest(this.validatedDepartureCode, this.passengerSelections.valueChanges.pipe(startWith(this.passengerSelections.value)))
      .pipe(this.debounce())
      .subscribe(([outRoute, passenger]) => {
        let ca = ChildrenAges.fromStrings(passenger.childrenAges, passenger.childCount)
        let passengers: Quote.IUserPassengers = {
          adults: { count: passenger.adultCount, category: 'Adult' },
          children: { count: ca.children, category: 'Child' },
          infants: { count: ca.infants, category: 'Infant' },
        }
        this.quoteData = of(
          new QuoteChanged(
            PriceSpecificationType.Travel,
            new UserQuoteDetails(
              null,
              passengers,
              this.travelWire.departureOut.routes.filter((r) => r.routeCode == outRoute).map((r) => r.description)[0]
            ),
            new OneWayUserQuoteDetails(),
            null
          )
        )
      })
  }

  private agentPreferredRoute(agentWire: AgentProfileWire) {
    this.preferredAgentRoute = agentWire && agentWire.agentDetails.preferredRouteCode

    if (this.isAgentRoute) this.outboundRouteCode.setValue(this.preferredAgentRoute)
  }

  private backendParametersChanged() {
    let subscription: Subscription = null
    this.travelForm.valueChanges
      // We need to ensure that there is at least on change because we skip it later.
      // If all five parameters are supplied with parameters there would be no change
      // But in case with not all parameters we would like to skip the first change or
      // We would make double requests
      .pipe(startWith(this.travelForm.value))
      .pipe(
        map((v) => ({
          departureRoute: v.departureSelections.outboundRouteCode,
          code: v.code,
          departureDate: v.departureSelections.outboundDepartureDate,
          numberOfPassengers: +v.passengerSelections.adultCount + +v.passengerSelections.childCount,
          childAges: v.passengerSelections.childrenAges
            .filter((_, i) => i < v.passengerSelections.childCount)
            .map((r) => (r === '' ? 10 : +r)),
          vehicleType: v.vehicleSelections && (this.travelWire.vehicles.vehicleTypes.find((vt) => vt.code === v.vehicleSelections.vehicle) || v.vehicleSelections.vehicle === "NCAR") ? v.vehicleSelections.vehicle : 
          this.travelWire.vehicles.vehicleTypes?.length > 0 ? this.travelWire.vehicles.vehicleTypes[0].code : '',
          trailerType: (v.vehicleSelections && v.vehicleSelections.trailer) || '',
          vehicleCount: (v.vehicleSelections && +v.vehicleSelections.count) || 1,
        }))
      )
      .pipe(distinctUntilChangedDeep())
      .pipe(skip(1))
      .subscribe((a) => {
        this.layoutState.setIsLoading(true)
        if (subscription) subscription.unsubscribe()
        let p = {
          ...a,
          //returnDate will not trigger a request, it is just pass through so we always can set return date picker to what we get from backend
          returnDate: this.travelForm.get('departureSelections.returnDepartureDate').value,
        }
        subscription = this.travelService.getTravelFlow(this.salesChannel(), p).subscribe((s) => {
          this.layoutState.setIsLoading(false)
          this.handleDataFromBackend(s)
        })
      })
  }

  private ensureAgentLoggedIn() {
    if (this.isAgentRoute) {
      try {
        this.travelService.ensureAgentAuth()
      } catch (e) {
        this.hybridService.agentLogin()
      }
    }
  }

  private handleDataFromBackend(res: ITravelWire) {
    this.travelWire = res
    this.validatedDepartureCode.next(res.departureOut.routeCode)
    this.travelForm.patchValue({
      departureSelections: {
        outboundRouteCode: res.departureOut.routeCode,
        outboundDepartureDate: res.departureOut.date.selectedDate,
        returnDepartureDate: res.departureReturn.date.selectedDate,
      },
      vehicleSelections: {
        vehicle: res.vehicles.chosenVehicleTypeCode,
      },
      code: res.validatedOrSupplied,
      passengerRanges: res.passengerRanges,
    })
    if (this.travelWire.vehicles.vehicleTypes.length) this.vehicleSelections.enable()
    else this.vehicleSelections.disable()
    this.backendData.next(res)
    this.codeStatus = res.codeStatus
    this.resourceService
      .loadResourcesPromise(
        this.outboundRouteCode.value,   
        this.locale,
        ['Travel', 'Account', 'Menu', 'Common', 'Currency', 'Quote'].concat(this.isAgentRoute ? ['Agent', 'MenuAgent'] : [])
      )
  }

  private vehicleChanges() {
    merge(this.backendData, this.travelForm.valueChanges)
      .pipe(this.debounce())
      .pipe(distinctUntilChangedDeep())
      .subscribe(() => {
        const value = this.travelForm.value as TravelForm
        this.setMultipleVehicles(value.vehicleSelections && value.vehicleSelections.vehicle, value.departureSelections.outboundRouteCode)
        const sv = selectedVehicle(this.travelWire.vehicles, this.vehicleSelections)
        this.showPets = (sv && sv.petsAllowed) === true
        if (this.showPets) this.frontendErrors.pets.disable()
      })
  }

  private trailerSelectionChanged() {
    this.vehicleSelections
      .get('trailer')
      .valueChanges.pipe(this.debounce())
      .pipe(filter((a) => !!a))
      .subscribe((v) => {
        this.frontendErrors.trailer.disable()
      })
  }

  private oneWayChanged() {
    let oneway = this.departureSelections.get('oneWay')
    oneway.valueChanges.pipe(startWith(oneway.value)).subscribe((s) => {
      let returnDate = this.departureSelections.get('returnDepartureDate')
      if (s) returnDate.disable()
      else returnDate.enable()
    })
  }

  private setStorageDataOnParamsOnRevisit(params: TravelParams) {
    if (params.any()) {
      return
    }
    const step = this.stepService.getStep('/')

    if (!step) {
      return
    }
    const stepValues = this.stepService.unwrapStepValues(step)

    // route
    params.routeOut = stepValues.outboundRouteId
    if (stepValues.outboundDepartureDate) {
      params.depDateOut = JsJodaConvert(stepValues.outboundDepartureDate).toDate()
      params.depDateOutJoda = stepValues.outboundDepartureDate.toLocalDate()
    }
    params.routeHome = stepValues.returnRouteId
    if (stepValues.returnDepartureDate) {
      params.depDateHome = JsJodaConvert(stepValues.returnDepartureDate).toDate()
      params.depDateHomeJoda = stepValues.returnDepartureDate.toLocalDate()
    }

    // passengers
    params.adl = stepValues.adults
    params.chd = stepValues.children
    params.inf = stepValues.infants // This will be removed due to child-age selector.
    params.pet = stepValues.pets
    params.chdAges = stepValues.childrenAges

    // vehicles
    if (stepValues.vehicleAndTrailerTypes && stepValues.vehicleAndTrailerTypes.length) {
      params.vty = stepValues.vehicleAndTrailerTypes[0].vehicleType || 'NCAR'
      params.tty = stepValues.vehicleAndTrailerTypes[0].trailerType
      params.vehicleCount = stepValues.vehicleAndTrailerTypes[0].count
    }

    // productCode
    params.product =
      step.productCode && step.productCode.selectionType === SelectionTypeEnum[SelectionTypeEnum.User] ? stepValues.productCode : ''
    params.offer = step.offerCode.selectionType === SelectionTypeEnum[SelectionTypeEnum.User] ? stepValues.offerCode : ''
  }

  private salesChannel(): string {
    return this.isAgentRoute ? '3PD' : 'PIB'
  }

  private continueButtonEnabledChanged() {
    this.travelForm.statusChanges.pipe(this.debounce()).subscribe((s) => (this.buttonState = s == 'VALID' ? 'activated' : 'disabled'))
  }
}
